import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Carpool App Template | Carpool App Design Template | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/carpool-mobile-app/"
    metaDescription="Looking to design your own mobile carpool app? Save mountains of time with our carpool app design template. Uizard: app design made easy."
    description="
    h2:Bring your idea to life with our carpool app design template for mobile
    <br/>
    Looking to design your own mobile carpool app? Save mountains of time with our carpool app design template. As with all our <a:https://uizard.io/templates/>UI templates</a>, our carpool design comes with everything your project could possibly need from all the core screens right through to the must-have UI elements.
    <br/>
    h3:Carpool app UI design made easy with rapid customization
    <br/>
    Use our carpool app template as it is or customize the template with our easy-to-use drag-and-drop editor. Uizard comes packed with a load of premade UI design components that make designing your app faster and easier than ever before. Bring your <a:https://uizard.io/mockups/>mockup</a> to life today.
    <br/>
    h3:Design apps with the power of AI
    <br/>
    Looking to fast-track the success of your carpool UI project? With the power of <a:https://uizard.io/ai-design/>AI app design</a>, anything is possible. Update text elements with our Text Assistant feature or update the entire theme of our template with Theme Generator.
    "
    pages={[
      "Stunning app landing page with user onboarding mockup",
      "Journey search mockup screen to showcase your app's core functionality",
      "Car selection screen with more information and core CTAs",
      "'Journey confirmed' screen mockup to showcase user end-to-end journey",
    ]}
    projectCode="pboRZvg9RAfbjlvjmlzQ"
    img1={data.image1.childImageSharp}
    img1alt="carpool mobile app design template overview"
    img2={data.image2.childImageSharp}
    img2alt="carpool mobile app design template search screen"
    img3={data.image3.childImageSharp}
    img3alt="carpool mobile app design template order screen"
    img4={data.image4.childImageSharp}
    img4alt="carpool mobile app design template profile"
    img5={data.image5.childImageSharp}
    img5alt="carpool mobile app design template summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/carpool-mobile-app/carpooling-app-mobile-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/carpool-mobile-app/carpooling-app-mobile-search.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/carpool-mobile-app/carpooling-app-mobile-order.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/carpool-mobile-app/carpooling-app-mobile-profile.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/carpool-mobile-app/carpooling-app-mobile-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
